/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_appsync_graphqlEndpoint": "https://7kgdds5sd5c4xff3mcspfwidoi.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-wgpup3pybnc35h7w7izpb4hfl4",
    "aws_content_delivery_bucket": "nursync-20231122004829-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-2",
    "aws_content_delivery_url": "https://d1r01bjqj3lal0.cloudfront.net"
};


export default awsmobile;
