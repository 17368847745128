import './css/App.css';
import './css/adminpage.css';
import { dbService } from './js/firebase';
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from 'react';
import useWindowWidth from './js/userwindow';
import DrugList from './js/apicall';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

function App() {

  const width = useWindowWidth()//사용자 width

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(dbService, "Patients"));
      setItems(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <div className="NurSyncWeb" style={{ width: width }}>
        <div className="NavbarLogoLeft" style={{ float: 'left', position: 'fixed', background: '#ffffff' }}>
          <div className="Navbarcontainer">
            <div className="NavbarContent" style={{ width: width, justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
              <div className="Vector" style={{ width: (141 / 1440) * width, height: 23.06 }}>
                <div>로고</div>
              </div>
              <div className="NavbarMenu" style={{ gap: (32 / 1440) * width }}>
                <div className="NavbarLink">
                  <p className="Home" style={{ color: 'black', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>Home</p>
                </div>
                <div className="NavbarLink">
                  <p className="About" style={{ color: 'black', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>About</p>
                </div>
                <div className="NavbarLink">
                  <p className="Services" style={{ color: 'black', fontSize: 14, fontFamily: 'Pretendard', fontWeight: '400', wordWrap: 'break-word' }}>Services</p>
                </div>
                <div className="NavbarButton" style={{ height: 'auto', paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: 8, background: '#2A4678', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'flex' }}>
                  <div className="Contact" style={{ color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>Contact</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="HeroStack" style={{ alignSelf: 'stretch', paddingLeft: 24, paddingRight: 24, paddingTop: 128, paddingBottom: 64, background: '#F2F4F9', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 80, display: 'flex' }}>
          <div className="SmallContainer" style={{ width: width, height: 295.83, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 40, display: 'flex' }}>
            <div className="TitleSection" style={{ alignSelf: 'stretch', height: 295.83, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'flex' }}>
              <div className="Content" style={{ alignSelf: 'stretch', height: 214.83, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'flex' }}>
                <div className="Vector" style={{ width: 280, height: 163.83, background: "#e8d581" }}>
                  HelloWorld!
                </div>
                <p className="Room821" style={{ height: 27, alignSelf: 'stretch', textAlign: 'center', color: '#212121', fontSize: 18, fontFamily: 'Pretendard', fontWeight: '700', wordWrap: 'break-word' }}>무한한 가능성, NurSync에서 만나보세요.</p>
              </div>
              <div className="Actions" style={{ paddingTop: 16, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'inline-flex' }}>
                <div className="Button" style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 12, paddingBottom: 12, background: '#2A4678', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'flex' }}>
                  <div className="Contact" style={{ color: 'white', fontSize: 12, fontFamily: 'Pretendard', fontWeight: '500', wordWrap: 'break-word' }}>Contact</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='firebase_data'>
          {items.length > 0 ? (
            <ul>
              {items.map(item => (
                <div key={item.id}>
                  {Object.entries(item).map(([key, value]) => (
                    <p key={key}>{`${key}: ${value}`}</p> // 모든 데이터 키-값 쌍을 표시합니다.
                  ))}</div>
              ))}
            </ul>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        {/*
        <div className="App">
          <DrugList />
        </div>
        */}
      </div>
    </div >
  );
}

export default App;
