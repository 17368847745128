// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBh2sY2cImUj7AioCGAii2zjcbyMM8nzuM",
  authDomain: "nursync-131b5.firebaseapp.com",
  projectId: "nursync-131b5",
  storageBucket: "nursync-131b5.appspot.com",
  messagingSenderId: "153878603909",
  appId: "1:153878603909:web:9b84da82f65834af1d61ec"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//db사용
export const dbService = getFirestore(app);